import logo from "./logo.svg";
import "./App.css";
import { JaaSMeeting } from "@jitsi/react-sdk";

const JAAS_MEETING_APP_ID =
  "vpaas-magic-cookie-a9f5b3c5d04949a09c27e0816b859fb3";
const JAAS_MEETING_ROOM_NAME = "DBS Meetings";

function App() {
  const onGetJaaSMeetingIFrameRef = (node) => {
    node.style.height = "100vh";
  };

  const onJaaSMeetingReadyToClose = () => {
    window.location.reload();
  };

  /*
   * Render
   **/

  return (
    <JaaSMeeting
      appId={JAAS_MEETING_APP_ID}
      roomName={JAAS_MEETING_ROOM_NAME}
      configOverwrite={{
        disableThirdPartyRequests: true,
        disableLocalVideoFlip: true,
        backgroundAlpha: 0.5,
      }}
      interfaceConfigOverwrite={{
        VIDEO_LAYOUT_FIT: "nocrop",
        MOBILE_APP_PROMO: false,
        TILE_VIEW_MAX_COLUMNS: 4,
      }}
      getIFrameRef={onGetJaaSMeetingIFrameRef}
      onReadyToClose={onJaaSMeetingReadyToClose}
    />
  );
}

export default App;
